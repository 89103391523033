import { CONNECTION_MESSAGES, CONNECTION } from '../constants'

export const updateConnectionStatus = status => {
  return {
    type: 'UPDATE_CONNECTION_STATUS',
    payload: {
      connection: {
        status,
        description: CONNECTION_MESSAGES[status],
      },
    },
  }
}

export const showLoader = () => {
  return {
    type: 'LOADER',
    payload: {
      isLoading: true,
    },
  }
}

export const hideLoader = () => {
  return {
    type: 'LOADER',
    payload: {
      isLoading: false,
    },
  }
}

export const updateInstagramServiceStatus = ({ isStopped }) => {
  return {
    type: 'INSTAGRAM',
    payload: {
      instagram: {
        isStopped,
      },
    },
  }
}

export const notifyWhenQueueFinished = () => {
  return {
    type: 'ALERT_WHEN_QUEUE_FINISHED',
    payload: {
      notifyWhenQueueFinished: true,
    },
  }
}

export const printLog = (line, newLine = true) => {
  return {
    type: 'PRINT_LOG',
    payload: {
      line: String(line),
      newLine,
    },
  }
}

export const clearLog = () => {
  return {
    type: 'CLEAR_LOG',
    payload: {},
  }
}

export const setUser = user => {
  return {
    type: 'SET_USER',
    payload: {
      user,
    },
  }
}

export const showErrorMessage = error => {
  return {
    type: 'SHOW_ERROR',
    payload: {
      error,
    },
  }
}

export const sendMetrikaEvent = (target, data = {}) => {
  console.log('ym', 53083903, 'reachGoal', target, data)

  typeof window.ym !== 'undefined' && window.ym(53083903, 'reachGoal', target, data)

  return {
    type: 'METRIKA',
    payload: {},
  }
}

export const updateStats = data => {
  return {
    type: 'UPDATE_STATS',
    payload: {
      stats: data || {},
    },
  }
}

export const updateConfig = (config) => {
  return {
    type: 'UPDATE_CONFIG',
    payload: {
      config: config,
    },
  }
}

export const maybeOpenAd = (options = {}) => {
  return {
    type: 'OPEN_AD',
    payload: options
  }
}

export const updateSubscriptions = (subscriptions = []) => {
  return {
    type: 'UPDATE_SUBSCRIPTIONS',
    payload: {
      subscriptions,
    }
  }
}

export const updateLicense = ({ isLicenseValid, isTrialValid } = {}) => {
  return {
    type: 'UPDATE_LICENSE',
    payload: {
      isLicenseValid, isTrialValid,
    },
  }
}
