import { CHARGEBEE_PLAN_IDS, BACKEND_URL } from './constants'

export const shouldCheckLicense = () => {
  return true
  // return false
}

export const checkLicenseCode = code => {
  return code && code.replace(' ', '').toUpperCase() === 'RE13ASJG'
}

export const checkTrial = installedAt => {
  return Date.now() < installedAt + 1000 * 60 * 60 * 24 * 7
}

export const isTrialValid = (subscription = {}) => {
  const trialEndTime = parseInt(subscription.trial_end) * 1000

  return Date.now() <= new Date(trialEndTime)
}

export const isLicenseValid = (subscription = {}) => {
  const paidEndTime = parseInt(subscription.current_term_end) * 1000

  return Date.now() <= new Date(paidEndTime)
}

export const isValid = (subscription = {}) => {
  return subscription.status == 'active'
      || subscription.status == 'in_trial'
      // || subscription.status && subscription.status != 'cancelled'
      || isTrialValid(subscription)
      || isLicenseValid(subscription)
}

export const checkHasAccess = (subs = []) => {
  return subs.filter(isValid)
}

export const fetchSubscriptionData = async username => {
  if (!username) {
    return
  }

  const url = `${BACKEND_URL}/?username=${username}`

  const { results = [] } = await fetch(url).then(res => res.json())

  const subscriptions = results
    .filter(s => CHARGEBEE_PLAN_IDS.includes(s.plan_id))
    .map(s => ({
      isTrialValid: isTrialValid(s),
      isLicenseValid: isLicenseValid(s),
      isValid: isValid(s),
      trialStartTime: new Date(parseInt(s.trial_start) * 1000),
      trialEndTime:   new Date(parseInt(s.trial_end) * 1000),
      paidStartTime:  new Date(parseInt(s.current_term_start) * 1000),
      paidEndTime:    new Date(parseInt(s.current_term_end) * 1000),
      nextBilling:    new Date(parseInt(s.next_billing_at) * 1000),
      ...s,
    }))
    // .sort((s1, s2) => s2.isValid ? s1.isValid ? +1 : -1 : -1)
    .sort((s1, s2) => s2.current_term_end - s1.current_term_end)

  console.log('sorted_subscriptions', subscriptions)

  return subscriptions
}
