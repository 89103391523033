import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import schedule from '../scheduler'

import scripts from './'

import {
  instagramUrl,
  sleep,
} from './util'

export const _schedule = {
  name: 'Schedule any action',
  description: `
    Kickspan Influencer will perform any action on your behalf for a set period of time.
    Simply choose how you’d like to grow, configure your settings and a select time frame.
    
    Let us do the rest!
  `,
  isPRO: true,
  params: [
    {
      name: 'scriptName',
      type: 'dropdown',
      labelText: 'Choose Action',
      defaultValue: 'like_user',
      values: [
        'look_stories',
        'look_many_stories',
        'look_stories_user_fans',
        'schedule',
        'test',
        'stop',
        'load_emails',
        'print_dm',
        'send_dm',
        'comment_by_hashtag',
        'like_my_feed',
        'like_by_hashtag',
        'like_user_fans',
        'like_hashtag_fans',
        'like_user',
        'ultimate_like',
        'comment_by_user',
        'follow_by_hashtag',
        'like_location',
        'load_pictures',
        'load_stories',
        'find_nondual_followings',
        'load_users',
        'load_followers',
        'like_followers',
        'like_fans',
      ],
    },
    // {
    //   name: 'timeout',
    //   type: 'number',
    //   labelText: 'Start after (minutes)',
    //   defaultValue: 0,
    //   values: [0, 60, 120, 240, 300, 360],
    // },
    {
      name: 'period',
      type: 'number',
      labelText: 'Stop after (minutes)',
      defaultValue: 60,
      values: [5, 10, 20, 60, 120, 240, 300, Infinity],
    },
    {
      name: 'scriptParams',
      type: 'textarea',
      labelText: 'Script params in JSON',
      defaultValue: '{}',
      isHidden: true,
    },
  ],
  run: async ({ timeout, period, scriptName, scriptParams }, printLog = console.log) => {
    printLog(`Starting a script ${scriptName} ... `)

    const script = scripts[scriptName]

    if (!script) {
      printLog(`Script ${scriptName} not found`)
      return
    }

    printLog(`Running script ${scriptName} ... `)

    let _params = {}

    try {
      _params = JSON.parse(scriptParams)
    } catch (err) {
      printLog(`Error: ${err.message}`)
      return
    }
    if (!_params.nPhotos) {
      // FIX for Infinity becoming null in JSON
      _params.nPhotos = Infinity
    }

    printLog(`Run with params = ${JSON.stringify(_params)}`)

    script.run(_params, printLog)
      // .then(res => this.props.sendMetrikaEvent(`task-success-${scriptName}`))
      .catch(err => {
        console.error(err)
        printLog(`Error: ${err.message}`)
        alert(err.message)
        // this.props.sendMetrikaEvent(`task-error-${scriptName}`)
      })

    return new Promise((resolve) => {
      const startAt = Number(period) * 60 * 1000 + Date.now()
      printLog(`Stop at: ${new Date(startAt)}`)
      schedule.printLog = printLog
      schedule.add({ startAt, scriptName: 'stop', params: _params }, (err, res) => {
        printLog(`Killing all scripts...`)

        if (err) {
          printLog(`Error: ${err.message}`)
          alert(err.message)
        }

        if (res) {
          printLog(`Success: ${!!res}`)
        }

        console.log('error', err)
        console.log('result', res)

        resolve(err, res)

      })
    })

  },
}

export default _schedule
