window.SKIP_PROBABILITY = 0.1

import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

window.makeGenerator = makeGenerator

import {
  get_random,
  getCSV,
  download,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

window.download = download
window.getCSV = getCSV

import { likePhotosByUsername } from '../services'

import {
  safeMap,
  followList,
} from '../old/likeItems'

const populateEmail = user => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

  const [email, ...others] = Object.values(user)
    .filter(value => typeof value == 'string')
    .flatMap(value => value.match(emailRegexp) || [])
  console.log('found emails (using only first):', [email, ...others])

  return {
    ...user,
    email,
  }
}

export const scripts = {
  test: {
    name: 'Test text input',
    params: [
      { name: 'username', type: 'text', prefix: '@', defaultValue: 'danokhlopkov' },
      { name: 'password', type: 'text' },
    ],
    run: async ({ username, password } = {}) => {
      alert(username + ': ' + password)
    },
  },

  stop: {
    name: 'Stop tasks',
    description: `
      If you don't understand it, you probably don't need it yet.

      This task will kill all other requests. First, run some other task, them come here and schedule killing for 6-8 hours. That way, Infinity scripts will be killed even if you leave your PC running.

      Schedule stopping all the tasks.
    `,
    params: [],
    run: async () => {
      instagram.kill()
    },
  },

  print_dm: {
    name: 'See all messages',
    description: ``,
    params: [
      { name: 'thread_id', type: 'text' },
      { name: 'next_cursor', type: 'text' },
    ],
    run: async (
      { thread_id = null, next_cursor = '' },
      printLog = console.log,
    ) => {
      if (thread_id) {
        const { thread } = await instagram.request(
          { method: 'get_thread', params: [thread_id, next_cursor] },
          true,
        )

        printLog(
          `Thread with @${thread.thread_title}, ${thread.items.length} items`,
        )
        thread.items.forEach((item, index) => {
          const sender =
            item.user_id == thread.viewer_id
              ? 'me'
              : thread.users && thread.users[0].username
          printLog(`${sender}: ${item.text || item.item_type}`)
        })

        printLog(`next cursor: ${thread.next_cursor}`)
        printLog(`prev cursor: ${thread.prev_cursor}`)
        printLog(`newest cursor: ${thread.newest_cursor}`)
        printLog(`oldest cursor: ${thread.oldest_cursor}`)
      } else {
        const threads = await instagram.request(
          { method: 'get_inbox', params: [] },
          true,
        )

        threads.inbox.threads.forEach((thread, index) => {
          printLog(
            `ID: ${thread.thread_id}, title: ${
              thread.thread_title
            }, last: ${thread.last_permanent_item.text ||
              thread.last_permanent_item.item_type} `,
          )
        })
      }
    },
  },

  send_dm: {
    name: 'Send DM',
    description: ``,
    params: [
      { name: 'thread_id', type: 'text' },
      { name: 'text', type: 'text' },
    ],
    run: async ({ thread_id = null, text = '' }, printLog = console.log) => {
      if (!thread_id) {
        printLog(`Provide thread_id`)
        return
      }

      const { status } = await instagram.request(
        {
          method: 'send_direct_item',
          params: ['text', { thread: thread_id, text }],
        },
        true,
      )

      printLog(`status: ${status}`)
    },
  },

  like_my_feed: {
    name: 'Like New Posts From Users You Follow',
    description: `
      Will Like posts from your feed every 60 seconds
    `,
    isPRO: true,
    params: [
      {
        name: 'nPhotos',
        type: 'number',
        labelText: 'Number of photos',
        defaultValue: Infinity,
        values: [10, 20, 50, 100, 200, Infinity],
      },
    ],
    run: async ({ nPhotos }, printLog = console.log) => {
      printLog(`Fetching feed ... `)

      // Phase 1: set up feed generator
      const feed = instagram.page_generator({
        method: 'get_timeline',
        params: [],
      })

      // Phase 2: page

      const items = new Lazy(feed)
        .peek((page, index) =>
          printLog(`Page ${index}: Fetched ${page.num_results} items.`),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(page => makeGenerator(page.feed_items))
        .flat()

      // Phase 3: Map each photo into like

      const liked = items
        .filter(item => item.media_or_ad)
        .map(item => item.media_or_ad)
        .filter(skip(() => instagram.isStopped, printLog))
        .filter((item, index) => {
          if (item.has_liked) {
            printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
            return false
          }

          return true
        })
        .take(nPhotos)
        .peek((item, index) =>
          printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
        )
        .map(item => instagram.request({ method: 'like', params: [item.id] }))
        .peek(({ status }) => printLog(status || 'error', false))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

      // Phase 4: run. if nPhotos is given, take only that much
      const results = await liked.unwrap({ accumulate: true })

      printLog(`FINISHED,
        Total requests: ${results.length},
        Success: ${results.filter(item => item.status == 'ok').length} items,
        Errors: ${results.filter(item => item.status == 'error').length} items`)

      return results
    },
  },

  like_by_hashtag: {
    name: 'Like photos from hashtag feed',
    isPRO: true,
    params: [
      {
        name: 'hashtag',
        type: 'text',
        labelText: 'Hashtag',
        prefix: '#',
        defaultValue: 'cats',
      },
      {
        name: 'nPhotos',
        type: 'number',
        labelText: 'Number of photos',
        values: [10, 20, 50, 200, 500],
        defaultValue: 200,
      },
    ],
    run: async ({ hashtag, nPhotos }, printLog = console.log) => {
      if (!hashtag) {
        throw new Error(`Empty hashtag field!`)
      }

      printLog(`Fetching photos by hashtag: #${hashtag} ... `)

      // Phase 1: set up feed generator
      const feed = instagram.page_generator({
        method: 'get_hashtag_feed',
        params: [hashtag],
      })

      // Phase 2: pages to list
      const items = new Lazy(feed)
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((page, index) =>
          printLog(`Page ${index}: Fetched ${page.num_results} items.`),
        )
        .map(page => makeGenerator(page.items))
        .flat()

      // Phase 3: like each from List
      const liked = items
        .filter(skip(() => instagram.isStopped, printLog))
        .filter((item, index) => {
          if (item.has_liked) {
            printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
            return false
          }

          return true
        })
        .take(nPhotos)
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((item, index) =>
          printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
        )
        .map(item => instagram.request({ method: 'like', params: [item.id] }))
        .peek(({ status }) => printLog(status || 'error', false))

      // Phase 4: run. if nPhotos is given, take only that much
      const results = await liked.unwrap({ accumulate: true })

      printLog(`FINISHED,
        Total requests: ${results.length},
        Success: ${results.filter(item => item.status == 'ok').length} items,
        Errors: ${results.filter(item => item.status == 'error').length} items`)

      return results
    },
  },

  like_user_fans: {
    name: 'Like posts from users fans',
    description: `
    Will Like posts from users' fans every 60 seconds
    
    `,
    isPRO: true,
    params: [
      {
        name: 'username',
        type: 'text',
        labelText: 'Username',
        prefix: '@',
        defaultValue: 'danokhlopkov',
      },
      {
        name: 'nPhotos',
        type: 'number',
        labelText: 'Total number of photos to like',
        values: [1, 3, 10, 20, 50, 200, 500],
        defaultValue: 200,
      },
      {
        name: 'nLikePhotos',
        type: 'number',
        labelText: 'Number of photos to like for each user',
        values: [1, 2, 3],
        defaultValue: 3,
      },
    ],
    run: async (
      { username, nPhotos, nLikePhotos },
      printLog = console.log,
    ) => {
      if (!username) {
        throw new Error(`Empty hashtag field!`)
      }

      printLog(`Fetching photos by user: @${username} ... `)

      const { user } = await instagram.request({
        method: 'get_user_info',
        params: [username],
      })

      // Phase 1: set up feed generator
      const feed = instagram.page_generator({
        method: 'get_user_feed',
        params: [user.pk],
      })

      // Phase 2: pages to list
      const items = new Lazy(feed)
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((page, index) =>
          printLog(`Page ${index}: Fetched ${page.num_results} photos.`),
        )
        .map(page => makeGenerator(page.items))
        .flat()

      // Phase 3: find likers from each photo
      const fans = items
        .filter(skip(() => instagram.isStopped, printLog))
        .peek((item, index) =>
          printLog(`Photo: ${getURL(item)} has likes: ${item.like_count}`),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(item =>
          instagram.page_generator({
            method: 'get_media_likers',
            params: [item.id],
          }),
        )
        .map(feed =>
          new Lazy(feed)
            .peek(page => printLog(` ${page.user_count} likers`, false))
            .map(page => makeGenerator(page.users))
            .flat(),
        )
        .flat()

      // Phase 4: fetch fans' photos

      const photos = fans
        .filter(user => !!user.username)
        .filter(skip(() => instagram.isStopped, printLog))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((user, index) =>
          printLog(`Fan: @${user.username} ${getURL(user)}`),
        )
        .map(user =>
          instagram.request({ method: 'get_user_feed', params: [user.pk] }),
        )
        .map(user_feed => makeGenerator(user_feed.items.slice(0, nLikePhotos)))
        .flat()

      // Phase 5: like!

      const liked = photos
        .filter(skip(() => instagram.isStopped, printLog))
        .filter((item, index) => {
          if (!item.code) {
            printLog(`Skipping ${index}: Photo was deleted`)
            return false
          }

          if (item.has_liked) {
            printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
            return false
          }

          return true
        })
        .take(nPhotos)
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((item, index) =>
          printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
        )
        .map(item => instagram.request({ method: 'like', params: [item.id] }))
        .peek(({ status }) => printLog(status || 'error', false))

      // Phase 4: run. if nPhotos is given, take only that much
      const results = await liked.unwrap({ accumulate: true })

      printLog(`FINISHED,
        Total requests: ${results.length},
        Success: ${results.filter(item => item.status == 'ok').length} items,
        Errors: ${results.filter(item => item.status == 'error').length} items`)

      return results
    },
  },

  like_hashtag_fans: {
    name: 'Like photos from hashtag fans',
    description: `
    Will Like posts from your feed every 5 seconds.
    Kickspan will target users who follow a particular hashtag
    and likely have high-interest in that niche.

    `,
    isPRO: true,
    params: [
      {
        name: 'hashtag',
        type: 'text',
        labelText: 'Fans of Hashtag',
        prefix: '#',
        defaultValue: 'cats',
      },
      {
        name: 'nPhotos',
        type: 'number',
        labelText: 'Total number of photos to like',
        values: [1, 3, 10, 20, 50, 200, 500],
        defaultValue: 200,
      },
      {
        name: 'nLikePhotos',
        type: 'number',
        labelText: 'Number of photos to like for each user',
        values: [1, 2, 3],
        defaultValue: 3,
      },
    ],
    run: async (
      { hashtag, nPhotos, nLikePhotos },
      printLog = console.log,
    ) => {
      if (!hashtag) {
        throw new Error(`Empty hashtag field!`)
      }

      printLog(`Fetching photos: #${hashtag} ... `)

      // Phase 1: set up feed generator
      const feed = instagram.page_generator({
        method: 'get_hashtag_feed',
        params: [hashtag],
      })

      // Phase 2: pages to list
      const items = new Lazy(feed)
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((page, index) =>
          printLog(`Page ${index}: Fetched ${page.num_results} photos.`),
        )
        .map(page => makeGenerator(page.items))
        .flat()

      // Phase 3: find likers from each photo
      const fans = items
        .filter(skip(() => instagram.isStopped, printLog))
        .peek((item, index) =>
          printLog(`Photo: ${getURL(item)} has likes: ${item.like_count}`),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(item =>
          instagram.page_generator({
            method: 'get_media_likers',
            params: [item.id],
          }),
        )
        .map(feed =>
          new Lazy(feed).map(page => makeGenerator(page.users)).flat(),
        )
        .flat()
        .filter(user => !!user)

      // Phase 4: fetch fans' photos

      const photos = fans
        .filter(user => !!user.username)
        .filter(skip(() => instagram.isStopped, printLog))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((user, index) =>
          printLog(`Fan: @${user.username} ${getURL(user)}`),
        )
        .map(user =>
          instagram.request({ method: 'get_user_feed', params: [user.pk] }),
        )
        .map(user_feed => makeGenerator(user_feed.items.slice(0, nLikePhotos)))
        .flat()

      // Phase 5: like!

      const liked = photos
        .filter(skip(() => instagram.isStopped, printLog))
        .filter((item, index) => {
          if (!item.code) {
            printLog(`Skipping ${index}: Photo was deleted`)
            return false
          }

          if (item.has_liked) {
            printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
            return false
          }

          return true
        })
        .take(nPhotos)
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek((item, index) =>
          printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
        )
        .map(item => instagram.request({ method: 'like', params: [item.id] }))
        .peek(({ status }) => printLog(status || 'error', false))

      // Phase 4: run. if nPhotos is given, take only that much
      const results = await liked.unwrap({ accumulate: true })

      printLog(`FINISHED,
        Total requests: ${results.length},
        Success: ${results.filter(item => item.status == 'ok').length} items,
        Errors: ${results.filter(item => item.status == 'error').length} items`)

      return results
    },
  },

  like_user: {
    name: 'Like User’s Posts',
    description: `
    Use 'Infinity' to like ALL user posts.
    `,
    params: [
      {
        name: 'username',
        type: 'text',
        prefix: '@',
        defaultValue: 'robertdowneyjr',
      },
      {
        name: 'nPhotos',
        type: 'number',
        labelText: 'Number of photos',
        values: [1, 2, 5, 10, 20, 50, 100, Infinity],
      },
    ],
    run: async ({ username, nPhotos } = {}, printLog = console.log) => {
      return likePhotosByUsername(username, nPhotos, printLog)
    },
  },

  ultimate_like: {
    name: 'Ultimate Liker',
    description: `
      Set up the will run FOREVER! Stop other tasks before running.
      It will like all the media from given accounts and hashtags.
    `,
    params: [
      {
        name: 'usernames',
        type: 'text',
        labelText: 'List of usernames, comma separated',
        defaultValue: 'danokhlopkov,caffeinum',
      },
      {
        name: 'hashtags',
        type: 'text',
        labelText: 'List of hashtags, comma separated',
        defaultValue: 'cats,dogs,pups',
      },
      {
        name: 'startEveryMinutes',
        type: 'text',
        labelText: '',
        defaultValue: '60',
      },
    ],
    run: async ({ usernames, hashtags } = {}, printLog = console.log) => {
      const parseCommaArray = str => str.replace(/\s#@/g, '').split(',')

      const _usernames = parseCommaArray(usernames)
      const _hashtags = parseCommaArray(hashtags)

      // Phase 1: set up feed generators
      const user_feed = _usernames.map(user =>
        instagram.page_generator({
          method: 'get_user_info',
          params: [user],
        }),
      )

      const hashtag_feed = _hashtags.map(hashtag =>
        instagram.page_generator({
          method: 'get_hashtag_feed',
          params: [hashtag],
        }),
      )

      return
    },
  },

  follow_by_hashtag: {
    name: 'Follow people who posts by hashtag',
    params: [
      {
        name: 'hashtag',
        type: 'text',
        prefix: '#',
        labelText: 'Hashtag',
        defaultValue: 'cats',
      },
      {
        name: 'nUsers',
        type: 'number',
        labelText: 'Number of users',
        values: [5, 10, 20, 50, 100],
      },
    ],
    run: async ({ hashtag, nUsers }, printLog = console.log) => {
      if (!hashtag) {
        throw new Error(`Empty hashtag field!`)
      }

      printLog(`Fetching photos by hashtag: #${hashtag} ... `)

      const { items } = await instagram.request({
        method: 'get_hashtag_feed',
        params: [hashtag],
      })

      printLog(`OK, ${items.length} results`, false)
      console.log(`URLS:`, items.map(instagramUrl))

      return followList(items.map(item => item.user), nUsers, printLog)
    },
  },

  like_location: {
    name: 'Like By Location',
    description: `
      Will like posts from the given location every 60 seconds
    `,
    params: [
      { name: 'location_name', type: 'text', labelText: 'Location name' },
      {
        name: 'nPhotos',
        type: 'number',
        labelText: 'Number of photos',
        defaultValue: 100,
        values: [10, 20, 50, 100, 200, 500],
      },
    ],
    run: async ({ location_name, nPhotos }, printLog = console.log) => {
      const { items: locations } = await instagram.request({
        method: 'search_location',
        params: [location_name],
      })

      if (!locations.length)
        throw new Error(`Location ${location_name} not found`)

      printLog(
        `Location search by '${location_name}': found ${locations.length} items.`,
      )

      const { location } = locations[0]

      printLog(`Using '${location.name}'`)

      const { items } = await instagram.request({
        method: 'get_location_feed',
        params: [location.pk],
      })

      printLog(`Loaded ${items.length} photos. Requested to like ${nPhotos}.`)

      if (!items.length) {
        printLog(
          `Sorry, no photos to like in this location. Try more specific name.`,
        )
        return
      }

      return safeMap(
        items.slice(0, nPhotos),
        item => instagram.request({ method: 'like', params: [item.id] }),
        printLog,
      )
    },
  },

  load_pictures: {
    params: [
      {
        name: 'username',
        type: 'text',
        prefix: '@',
        labelText: 'Username',
        defaultValue: 'danokhlopkov',
      },
      {
        name: 'max_id',
        type: 'text',
        labelText: 'max_id (leave empty)',
      },
    ],
    run: async ({ username, max_id }, printLog = console.log) => {
      const {
        user: { pk, media_count },
      } = await instagram.request({
        method: 'get_user_info',
        params: [username],
      })

      if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

      const { items, next_max_id } = await instagram.request({
        method: 'get_user_feed',
        params: [pk, max_id],
      })

      printLog(
        `Loaded a batch of ${items.length} items. Total users media: ${media_count}`,
      )
      printLog(
        `You can access a next batch manually using this id: ${next_max_id}`,
      )
      printLog(`Download this batch: downloadCSV()`)

      window.items = items
      window.downloadCSV = () =>
        download(`items_${username}.csv`, getCSV(items))

      // downloadCSV()

      return
    },
  },

  load_stories: {
    name: 'Download Stories',
    description: `
    Simply insert a username and we will download their current stories of your choice as an image or video.
    `,
    params: [
      {
        name: 'username',
        type: 'text',
        prefix: '@',
        labelText: 'Username',
        defaultValue: 'danokhlopkov',
      },
    ],
    run: async ({ username }, printLog = console.log) => {
      const {
        user: { pk, media_count },
      } = await instagram.request({
        method: 'get_user_info',
        params: [username],
      })

      if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

      const { broadcast, reel, post_live_item } = await instagram.request({
        method: 'get_user_story_feed',
        params: [pk],
      })

      console.log('Loaded')
      console.log('broadcast', broadcast)
      console.log('reel', reel)
      console.log('post_live_item', post_live_item)

      if (!reel) {
        printLog(`No stories for user @${username}. Abort`)
        return
      }

      const { items } = reel
      printLog(
        `Loaded ${
          items.length
        } stories for @${username}. Livestream: ${!!broadcast}. Finished streams: ${!!post_live_item}`,
      )
      printLog(`Download this batch: downloadCSV()`)

      window.items = items
      window.downloadCSV = () =>
        download(`stories_${username}.csv`, getCSV(items))

      items.map((item, index) => {
        printLog(`Story ${index + 1}: `)

        if (item.video_dash_manifest) {
          printLog(`Video`, false)
          const matches = item.video_dash_manifest.match(
            /<BaseURL>(.*?)<\/BaseURL>/g,
          )

          console.log('matches', matches)
          const urls = matches.map(token => token.replace(/<.*?>/g, ''))

          const types = ['Audio', 'Video', 'Video HD']

          urls.map((url, index) =>
            printLog(`<a href="${url}">Download ${types[index]}</a>`),
          )
        } else if (item.image_versions2 && item.image_versions2.candidates) {
          printLog(`Photo`, false)
          const photo = item.image_versions2.candidates[0]

          console.log('photo', photo)
          const url = photo.url

          printLog(`<a href="${url}">Download Photo</a>`)
        } else {
          printLog(`Wrong format, skip`, false)
        }
      })
    },
  },

  load_users: {
    name: 'Iterate over list of usernames',
    params: [
      {
        name: 'usernameList',
        type: 'textarea',
        labelText: 'Username List (CSV)',
        defaultValue: 'id;username\n1;caffeinum\n2;danokhlopkov',
      },
      {
        name: 'isCSV',
        type: 'checkbox',
        prefix: '',
        labelText: 'Is CSV?',
        defaultValue: true,
      },
      {
        name: 'separator',
        type: 'text',
        labelText: 'CSV separator',
        defaultValue: ';',
      },
      {
        name: 'key',
        type: 'text',
        labelText: 'CSV Username Key',
        defaultValue: 'username',
      },
      {
        name: 'method',
        type: 'text',
        labelText:
          'Run request for each user (get_user_info, get_user_feed, get_user_story_feed)',
        defaultValue: 'get_user_info',
      },
    ],
    run: async (
      {
        usernameList,
        method = 'get_user_info',
        separator = ',',
        key = 'username',
        isCSV = true,
        isFullInfo = false,
        onlyEmail = false,
      },
      printLog = console.log,
      timeout,
    ) => {
      let usernames = []

      if (!isCSV) {
        usernames = usernameList.split('\n')
      } else {
        // parse CSV
        const [_keys, ..._list] = usernameList.split('\n')

        const keys = _keys.split(separator) || []

        const list = _list.map(elem => {
          const values = elem.split(separator) || []

          return keys.reduce(
            (obj, key, index) => ({
              [key]: values[index],
              ...obj,
            }),
            {},
          )
        })

        usernames = list.map(elem => elem[key] || elem.username)
      }

      console.log('usernames', usernames)

      const users = new Lazy(makeGenerator(usernames))

      let users_container = users
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .peek(user => printLog(`user: @${user}: `))
        .map(user =>
          instagram
            .request({ method: 'get_user_info', params: [user] })
            .then(({ user }) => user),
        )
        .peek(user => printLog(`ok`, false))

      let users_requested

      if (method !== 'get_user_info') {
        users_requested = users_container
          .peek(user => printLog(`@${user.username} ${method} `))
          .map(user => instagram.request({ method: method, params: [user.pk] }))
          .peek(user => printLog(`ok`, false))
          .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      } else {
        users_requested = users_container
          .map(user => populateEmail(user))
          .peek(user => printLog(`, email: ${user.email || 'none'}`, false))
          .peek(user => console.log('user', user))
      }

      const user_info = await users_requested.unwrap(true)

      printLog(`User infos loaded: ${user_info.length}`)
      printLog(`You can access them in window.users or download using`)
      printLog(`\t\tdownload('users.csv', getCSV(users))`)

      window.users = user_info
      window.downloadCSV = (chunk_size = 1000, arr = user_info) => {
        for (let page = 0; page <= arr.length / chunk_size; page++) {
          download(
            `users_page_${page + 1}.csv`,
            getCSV(arr.slice(page * chunk_size, (page + 1) * chunk_size)),
          )
        }
      }

      downloadCSV()
    },
  },

  like_followers: {
    name: 'Like Recent Photos Of User Followers',
    description: `
      Kickspan will find a user’s followers and like 1 – 3 of their most recent posts.
    `,
    params: [
      {
        name: 'username',
        type: 'text',
        prefix: '@',
        labelText: 'Username',
      },
      {
        name: 'nFollowers',
        type: 'number',
        values: [1, 2, 5, 10, 20, 50, 200, 500],
        labelText: 'Number of followers',
      },
      {
        name: 'nLikePhotos',
        type: 'number',
        values: [1, 2, 3],
        labelText: 'How many photos to like',
      },
    ],
    run: async (
      { username, nFollowers = 3, nLikePhotos = 1 } = {},
      printLog = console.log,
    ) => {
      const {
        user: { pk },
      } = await instagram.request(
        { method: 'get_user_info', params: [username] },
        true,
      )

      if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

      // set up request
      const followers_list = instagram.page_generator({
        method: 'get_user_followers',
        params: [pk],
      })

      // configure paging

      const followers = new Lazy(followers_list)
        .peek((page, index) =>
          printLog(`Batch ${index} of followings loaded: ${page.users.length}`),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(page => makeGenerator(page.users))
        .flat()

      // if (!followers || !followers.length) throw new Error(`No followers: ${followers}`)

      const first_photos = followers
        .take(nFollowers)
        .peek(user => printLog(`Fetching feed for ${getURL(user)} ... `))
        .map(user =>
          instagram.request({ method: 'get_user_feed', params: [user.pk] }),
        )
        .peek(feed =>
          printLog(
            `loaded first ${feed.items && feed.items.length} items.`,
            false,
          ),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(feed => feed.items)
        .filter(items => !!items)
        .map(items => new Lazy(makeGenerator(items)).take(nLikePhotos))
        .flat()

      // like each from List
      const liked = first_photos
        .filter(skip(() => instagram.isStopped, printLog))
        .filter((item, index) => {
          if (item.has_liked) {
            printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
            return false
          }

          return true
        })
        .peek((item, index) =>
          printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
        )
        .map(item => instagram.request({ method: 'like', params: [item.id] }))
        .peek(({ status }) => printLog(status || 'error', false))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

      // Phase 4: run. if nPhotos is given, take only that much
      const results = await liked.unwrap({ accumulate: true })

      printLog(`FINISHED,
        Total requests: ${results.length},
        Success: ${results.filter(item => item.status == 'ok').length} items,
        Errors: ${results.filter(item => item.status == 'error').length} items`)

      return results
    },
  },

  like_fans: {
    name: 'Like people who like some user',
    description: `
      Fetch user pictures, take his pictures and like 1-2-3 photos of people who liked it.
      NEEDS extension version 1.2.2!
      Infinity mode will work until the list of likers and then until the list of pictures.
    `,
    params: [
      {
        name: 'username',
        type: 'text',
        prefix: '@',
        labelText: 'Username',
      },
      {
        name: 'nPhotos',
        type: 'number',
        values: [1, 2, 5, 10, 20, 50, Infinity],
        labelText: 'Number of user pictures to look into',
      },
      {
        name: 'nLikePhotos',
        type: 'number',
        values: [1, 2, 3],
        labelText: 'How many photos from each fan to like',
      },
    ],
    run: async (
      { username, nPhotos = 3, nLikePhotos = 1 } = {},
      printLog = console.log,
    ) => {
      if (!username) throw new Error(`No user id: ${pk}`)

      const {
        user: { pk },
      } = await instagram.request(
        { method: 'get_user_info', params: [username] },
        true,
      )

      if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

      // set up request

      const media = instagram.page_generator({
        method: 'get_user_feed',
        params: [pk],
      })

      // configure paging
      const photos = new Lazy(media)
        .peek((page, index) =>
          printLog(
            `Batch ${index} of photos loaded: ${page.items.length} items.`,
          ),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(page => makeGenerator(page.items))
        .flat()
        .take(nPhotos)

      const fans = photos
        .map(item =>
          instagram.request({ method: 'get_media_likers', params: [item.id] }),
        )
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(item => makeGenerator(item.users))
        .flat()

      const first_photos = fans
        .peek(user => printLog(`Fetching feed for ${getURL(user)} ... `))
        .map(user =>
          instagram.request({ method: 'get_user_feed', params: [user.pk] }),
        )
        .peek(feed =>
          printLog(
            `loaded first ${feed.items && feed.items.length} items.`,
            false,
          ),
        )
        .filter(skip(() => instagram.isStopped, printLog))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
        .map(feed => feed.items)
        .filter(items => !!items)
        .map(items => new Lazy(makeGenerator(items)).take(nLikePhotos))
        .flat()

      // like each from List
      const liked = first_photos
        .filter(skip(() => instagram.isStopped, printLog))
        .filter((item, index) => {
          if (item.has_liked) {
            printLog(`Skipping ${index} ${instagramUrl(item)} : Already liked`)
            return false
          }

          return true
        })
        .peek((item, index) =>
          printLog(`Liking item ${index}, ${instagramUrl(item)} ... `),
        )
        .map(item => instagram.request({ method: 'like', params: [item.id] }))
        .peek(({ status }) => printLog(status || 'error', false))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

      // Phase 4: run. if nPhotos is given, take only that much
      const results = await liked.unwrap({ accumulate: true })

      printLog(`FINISHED,
        Total requests: ${results.length},
        Success: ${results.filter(item => item.status == 'ok').length} items,
        Errors: ${results.filter(item => item.status == 'error').length} items`)

      return results
    },
  },
}

export default scripts
