import React, { useState } from 'react'
import closeImage from '../../../img/close.svg'

const modalStyle = {
  paddingTop: '250px',
  position: 'fixed',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  zIndex: 3,
  textAlign: 'center',
}

const closeButtonStyle = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  width: '20px',
  height: '20px',
  background: `url(${closeImage}) no-repeat center`,
  backgroundSize: '20px',
}

export default () => {
  const [isOpen, setIsOpen] = useState(true)

  return isOpen ? (
    <div style={modalStyle}>
      <div style={closeButtonStyle} onClick={() => setIsOpen(false)} />
      <p>Sorry, we don't work on mobile devices now</p>
      <p>BUT YOU CAN USE IT ON YOUR PC</p>
      <p>just click below to download the extension</p>
      <a
        className="btn btn-success btn-icon-split"
        style={{ width: '75%', height: '40px', lineHeight: '40px' }}
        href="https://chrome.google.com/webstore/detail/socialblaze-influencer/gmdbcccojpephnafmfpjobggopmnappm/"
      >
        Download
      </a>
    </div>
  ) : null
}
