import React from 'react'

export default () => (
<div className="container-fluid">
    <h1>Join SocialBlaze Family (tm) Waitlist!</h1>

    <div>
        We are developing a new innovative fully automatic engagement group: users from the group will automatically like each other.
        That will bring them to Instagram 'recommended' section and grow audience.
    </div>

    <div>
        Join the waitlist now and get one month free!
    </div>

    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
    <div id="mc_embed_signup">
        <form action="https://github.us20.list-manage.com/subscribe/post?u=91201205e23d1ace89740a8b9&amp;id=12d4453a9c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">

                <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">Email Address </label>
                    <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                </div>
                    <div id="mce-responses" className="clear">
                        <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                        <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                    </div>
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                        <input type="text" name="b_91201205e23d1ace89740a8b9_12d4453a9c" tabIndex="-1" defaultValue="" />
                    </div>
                    <div className="clear">
                        <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
                    </div>
            </div>
        </form>
    </div>
    <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
    {/* <script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[6]='IG_UNAME';ftypes[6]='text';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script> */}

</div>
)
