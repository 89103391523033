import { createStore, combineReducers } from 'redux'
import Honeybadger from 'honeybadger-js'
import { CONNECTION_MESSAGES, CONNECTION, AD_URL } from '../constants'

const initialState = {
  isLoading: false,
  isLicenseValid: false,
  isTrialValid: false,
  notifyWhenQueueFinished: false,
  instagram: {
    isStopped: true,
  },
  connection: {
    status: CONNECTION.UNKNOWN,
    description: '',
  },
  subscriptions: [],
  log: JSON.parse(localStorage.getItem('log')) || [],
  user: {},
  error: '',
  stats: {
    follower_count: null,
    following_count: null,
    likes: null,
    follows: null,
    full: {},
  },
  config: {},
  IS_AD_ENABLED: false,
}

const reducer = (state = initialState, action) => {
  if (action.type !== 'INSTAGRAM') {
    console.log('action', action)
  }

  if (action.type === 'CLEAR_LOG') {
    localStorage.setItem('log', JSON.stringify([]))

    return {
      ...state,
      log: [],
    }
  }

  if (action.type === 'PRINT_LOG') {
    const { line, newLine } = action.payload

    const log = newLine ? [...state.log, `<br>`, line] : [...state.log, line]

    localStorage.setItem('log', JSON.stringify(log))

    return {
      ...state,
      log,
    }
  }

  if (action.type === 'OPEN_AD') {
    const { IS_AD_ENABLED, config } = state

    // if (!config.JOINED_FAMILY && IS_AD_ENABLED) {
    if (!config.JOINED_FAMILY && IS_AD_ENABLED) {
    // if (IS_AD_ENABLED) {
      window.open(AD_URL)
    }
  }

  if (action.type === 'INSTAGRAM') {
    const { isStopped } = action.payload.instagram

    if (!state.isStopped && isStopped) {
      if (state.notifyWhenQueueFinished) {
        alert(`Queue finished!`)

        return {
          ...state,
          notifyWhenQueueFinished: false,
          isStopped,
        }
      }
    }
  }

  if (action.type === 'SET_USER') {
    const { user = {} } = action.payload

    Honeybadger.setContext({
      user_id: user.pk,
      user_username: user.username,
      user_email: user.public_email,
    })
  }

  return {
    ...state,
    ...action.payload,
  }
}

const store = createStore(reducer)

const { getState } = store

export default store
