import React from 'react'
import { Card, CardHeader, CardBody } from '../components/markup/Card'

export default () => (
  <div className="container-fluid">
    <h1>Privacy policy and Terms and conditions</h1>

    <div className="row">
      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
              Most important
          </CardHeader>
          <CardBody>
            <p>
              Don't use this tool to spam or to do any other illegal stuff. All
              at your own risk, guys. SocialBlaze team is not responsible for the
              actions taken by your account in Instagram using SocialBlaze tool.
              After you successfully log in our SocialBlaze extension, you
              automatically aggree with all these terms and conditions and
              privacy policy.
            </p>
          </CardBody>
        </Card>
      </div>

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            Passwords
          </CardHeader>
          <CardBody>
            <p>
              We don't store your Instagram passwords (feel free to audit the
              browser extension code) since everything is happening in your
              browser on your side. Even if we would save your credentials,
              Instagram wouldn't allow us to login - you would receive the
              'suspicious login from strange location' alert.
            </p>
          </CardBody>
        </Card>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            Your data
          </CardHeader>
          <CardBody>
            <p>
              SocialBlaze doesn't have any backend and runs completely on a client
              side - in Google Chrome browser. That means that none of your data
              is stored on our side - we just have not the place where to store
              it. Our browser extension do not send anywhere your Instagram
              credentials (feel free to audit it's code which is located here:
              https://github.com/instagrambot/gramup).
            </p>
          </CardBody>
        </Card>
      </div>

      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            SocialBlaze Family
          </CardHeader>
          <CardBody>
            <p>
              Being in SocialBlaze Family you allow us to control your Instagram account to perform
              searching, viewing and liking Instagram activities at any time without your additional permission.
              You also understand that any Instagram automation activity can lead to an account blocking.
            </p>
          </CardBody>
        </Card>
      </div>
    </div>

    <div className="row">
      <div className="col-lg-6">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">
            Anti-bot Instagram protection
          </CardHeader>
          <CardBody>
            <p>
              Instagram is constantly evolving it's anti-bot algorithms. That is
              why someday our automation tool may made your Instagram account
              banned by Instagram because the team were not be able to overcome
              this anti-automation algorithms. If you become blocked someday, we
              suggest you to stop using SocialBlaze dashboard and Chrome extension.
            </p>
          </CardBody>
        </Card>
      </div>
    </div>
  </div>
)
