import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, CardHeader, CardBody } from '../components/markup/Card'
import { Button } from '../components/dumb/Button'

import { userUrl } from '../scripts/util'

import { fetchSubscriptionData, isValid } from '../license'
import { updateSubscriptions } from '../redux/actions'

export const SubscriptionPage = props => {
  const fetchStatus = async username => {
    if (!props.user) {
      return
    }

    const subs = await fetchSubscriptionData(props.user.username) || []

    props.updateSubscriptions(subs)
  }

  useEffect(() => {
    fetchStatus()
  }, [props.user])

  if (!props.user) {
    return 'Not logged in'
  }

  return (
    <div className="container-fluid">
      <h1>My Subscription</h1>

      <div className="row">
        <div className="col-lg-12">
          <Card className="shadow mb-4">
            <CardHeader className="py-3">
              Subscriptions for{' '}
              <a
                href={userUrl(props.user)}
                target="_blank"
                rel="noreferrer noopener"
              >
                @{props.user.username}
              </a>
            </CardHeader>
            <CardBody>
              <Button
                className="btn-outline-primary"
                onClick={() => fetchStatus()}
              >
                Refresh
              </Button>
              &nbsp;
              <Button
                className="btn-outline-warning"
                onClick={() => window.open(`https://instaplana.chargebeeportal.com/portal`)}
              >
                Manage Subscriptions
              </Button>

              {!props.subscriptions.length && (
                <div>
                  <br />
                  No subscriptions, buy here:
                  <br />
                  <Button className="btn-danger" onClick={() => window.open(`https://instaplana.chargebee.com/hosted_pages/plans/socialblazetrial`)}>
                    PURCHASE
                  </Button>
                </div>
              )}

              {props.subscriptions.map(s => (
                <pre key={s.id}>
                  {'\n'}
                  Plan ID:{'\t'}<a href="https://instaplana.chargebeeportal.com/portal" target="_blank">{s.plan_id}</a>
                  {'\n'}
                  Status: {'\t'}{s.status}
                  {'\n'}
                  Trial Started:{'\t'}
                    {new Date(s.trialStartTime).toLocaleString()}
                  {'\n'}
                  Trial Active:{'\t'}
                    {new Date(s.trialEndTime).toLocaleString()}
                  {'\n'}
                  Paid until:{'\t'}
                    {new Date(s.paidEndTime).toLocaleString()}

                  {'\n\n'}

                  {!s.isLicenseValid && (
                    <Button className="btn-danger" onClick={() => window.open(`https://instaplana.chargebee.com/hosted_pages/plans/socialblazetrial`)}>
                      PURCHASE
                    </Button>
                  )}
                </pre>
              ))}
            </CardBody>
          </Card>
        </div>
      </div>

    </div>
  )
}

export default connect(
  state => ({ user: state.user, subscriptions: state.subscriptions }),
  { updateSubscriptions },
)(SubscriptionPage)
