import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import instagram from '../old/instagram_connector'
import scheduler from '../scheduler'

import { Button, InlineButton } from '../components/dumb/Button'
import { Card, CardHeader, CardBody } from '../components/markup/Card'

import { CONNECTION, IS_FAMILY_ENABLED, message } from '../constants'
import { printLog, updateConfig } from '../redux/actions'
import { download } from '../scripts/util'
import { sendConfig } from '../services'

@connect(
  ({
    stats, log, user, connection, config,
    subscriptions,
  }) => ({
    stats, log, user, connection, config,
    subscriptions,
  }),
  { printLog, updateConfig },
)
class SettingsPage extends React.Component {
  state = {
    info: {},
    config: null,
  }

  unfollowWhitelistRef = React.createRef()

  async componentDidMount() {
    const info = await instagram.request({ method: 'version' }, true)

    this.props.updateConfig(info.config)

    this.setState({
      info,
      config: info.config,
    })
  }

  async componentDidUpdate() {
    if (this.props.connection.status !== CONNECTION.LOGGED_IN) { return }
    if (this.state.info.status) { return }

    const info = await instagram.request({ method: 'version' }, true)

    this.props.updateConfig(info.config)

    this.setState({
      info,
      config: info.config,
    })

  }

  updateConfig = async (_updates) => {
    this.setState({
      config: null,
    })

    const new_config = await sendConfig(_updates)

    console.log('config', new_config)

    this.setState({
      config: new_config,
    })
  }

  render() {
    const { info, config } = this.state
    const { stats, user, log, connection } = this.props

    const { subscriptions } = this.props
    const [ activeSub = {} ] = subscriptions || []

    let unfollow_whitelist = []

    try {
      unfollow_whitelist = JSON.parse(localStorage.getItem('unfollow_whitelist')) || []
    } catch (err) {

    }

    return (
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          {/* <!-- Page Heading  --> */}
          <h1 className="h3 mb-0 text-gray-800">Settings</h1>
        </div>

        {/* <!-- Content Row  --> */}
        <div className="row">

          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                License
              </CardHeader>
              <CardBody>
                <table>
                  <tbody>
                  <tr>
                    <td>
                      Status
                    </td>
                    <td>
                      <strong>{activeSub.status}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Extension Active
                    </td>
                    <td>
                      {activeSub.isValid ? 'Yes' : 'No'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Plan ID
                    </td>
                    <td>
                      {activeSub.plan_id}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Username
                    </td>
                    <td>
                      @{activeSub.cf_instagram_username}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Trial Started At
                    </td>
                    <td>
                      {new Date(activeSub.trialStartTime).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Trial Active until
                    </td>
                    <td>
                      {new Date(activeSub.trialEndTime).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Paid until
                    </td>
                    <td>
                      {activeSub.paidEndTime ? new Date(activeSub.paidEndTime).toLocaleString() : 'Not paid'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Next Billing At
                    </td>
                    <td>
                      {activeSub.nextBilling ? new Date(activeSub.nextBilling).toLocaleString() : 'Not set'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Manage License
                    </td>
                    <td>
                      {activeSub.isLicenseValid
                        ? (
                          <div>

                            <Button
                              className="btn-outline-warning"
                              onClick={() => window.open(`https://instaplana.chargebeeportal.com/portal`)}
                            >
                              Manage
                            </Button>
                            <Button
                              className="btn-outline-danger ml-2"
                              onClick={() => window.open(`https://www.socialblaze.io/cancel`)}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <Button
                            className="btn-primary"
                            onClick={() => window.open(`https://instaplana.chargebee.com/hosted_pages/plans/socialblazetrial`)}
                            ym={`buy-license`}
                          >
                            Purchase
                          </Button>
                        )}
                    </td>
                  </tr>

                  </tbody>
                </table>

              </CardBody>
            </Card>
          </div>

          <div className="col-lg-6">

            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Like sleep interval
              </CardHeader>
              <CardBody>
                We recommend using normal speed to ensure no roadblocks occur.

                NOTE: This setting is reset after page update

                <br />

                <div className="d-flex align-items-center justify-content-between mt-2">

                  <Button
                    className="btn-danger"
                    onClick={() => alert(`Saved: ${window._sleep_timeout = 20} sec between actions.`)}
                    ym={`sleep-timeout-5-sec`}
                  >
                    <span className="text">Fast ~ 20 sec</span>
                  </Button>

                  <Button
                    className="btn-success"
                    onClick={() => alert(`Saved: ${window._sleep_timeout = 60} sec between actions.`)}
                    ym={`sleep-timeout-20-sec`}
                  >
                    <span className="text">Normal ~ 60 sec</span>
                  </Button>

                  <Button
                    className="btn-primary"
                    onClick={() => alert(`Saved: ${window._sleep_timeout = 120} sec between actions.`)}
                    ym={`sleep-timeout-120-sec`}
                  >
                    <span className="text">Slow ~ 120 sec</span>
                  </Button>

                </div>
              </CardBody>
            </Card>
          </div>


          <div className="col-lg-6">
            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Scheduled tasks
              </CardHeader>
              <CardBody>
                {!scheduler.tasks.length && (
                  <div>
                    No tasks, create at <Link to="/scripts/schedule">Schedule</Link>
                  </div>
                )}

                {scheduler.tasks.length !== 0 && (
                  <table style={{ margin: '0', overflow: 'scroll', width: '100%' }}>
                    <thead>
                      <tr>
                        <th>
                          scheduled
                        </th>
                        <th>
                          script
                        </th>
                        <th>
                          actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scheduler.tasks.map((task, index) => {
                        const { startAt, scriptName, params } = task

                        return (
                          <tr key={index}>
                            <td>
                              {new Date(startAt).toLocaleString()}
                            </td>
                            <td>
                              <Link to={`/scripts/${scriptName}`}>
                                {' '}{scriptName}{' '}
                              </Link>
                            </td>
                            <td>
                              <Link to={`/scripts/schedule?scriptName=${scriptName}&scriptParams=${JSON.stringify(params)}`}>
                                duplicate
                              </Link>
                            </td>
                            <td>
                              <InlineButton onClick={() => confirm('Delete task?') && scheduler.remove(task)}>
                                delete
                              </InlineButton>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}

              </CardBody>
            </Card>
          </div>


          <div className="col-lg-12">

            <Card className="shadow mb-4">
              <CardHeader className="py-3">
                Technical information
              </CardHeader>
              <CardBody>
                <table style={{ margin: '0', display: 'block', overflow: 'scroll' }}>
                  <tbody>
                    <tr>
                      <td>
                        Status
                      </td>
                      <td>
                        {info.status}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Connection
                      </td>
                      <td>
                        {connection.description}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Version
                      </td>
                      <td>
                        {info.version}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        User-Agent
                      </td>
                      <td>
                        <span style={{ margin: '0', display: 'block', overflowX: 'scroll', whiteSpace: 'nowrap' }}>
                          {info.user_agent}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Logs
                      </td>
                      <td>
                        <InlineButton onClick={() => download(`gramup_logs_${Date.now()}.html`, log.join("\n"))}>
                          Download logs ({log.length} lines)
                        </InlineButton>
                      </td>
                    </tr>

                  </tbody>
                </table>

              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsPage
