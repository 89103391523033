import React from 'react'
import { withRouter, HashRouter, Switch, Route } from 'react-router-dom'

import {
  DashboardPage,
  BlankPage,
  FamilyPage,
  DailyAdPage,
  FollowUserPeersPage,
  LegalPage,
  LikeHashtagPage,
  LikePage,
  LikeUserPage,
  LogsPage,
  ScriptPage,
  SupportUsPage,
  SubscriptionPage,
  SettingsPage,
  NotFoundPage,
  FAQPage,
} from './pages';

export default props => (
  <Switch>
    <Route exact path="/" component={DashboardPage} />
    <Route path="/logs" component={LogsPage} />
    <Route path="/like/user-medias" component={LikeUserPage} />
    <Route path="/like/hashtag" component={LikeHashtagPage} />
    <Route path="/like" component={LikePage} />
    <Route path="/follow" component={FollowUserPeersPage} />
    <Route path="/script/:name" component={ScriptPage} />
    <Route path="/scripts/:name" component={ScriptPage} />
    <Route path="/daily-ad" component={DailyAdPage} />
    <Route path="/support-us" component={SupportUsPage} />
    <Route path="/blank" component={BlankPage} />
    <Route path="/empty" component={BlankPage} />
    <Route path="/404" component={NotFoundPage} />
    <Route path="/legal" component={LegalPage} />
    <Route path="/family" component={FamilyPage} />
    <Route path="/faq" component={FAQPage} />
    <Route path="/settings" component={SettingsPage} />
    <Route path="/subscription" component={SubscriptionPage} />
    <Route component={NotFoundPage} />
  </Switch>
)
