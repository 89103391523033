import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import scripts from '../../scripts'

import { CardFullWidthPage, ProBadge } from './'
import { Button } from '../dumb/Button'
import { message } from '../../constants'
import schedule from '../../scheduler'

import {
  notifyWhenQueueFinished,
  showLoader,
  hideLoader,
  printLog,
  sendMetrikaEvent,
  maybeOpenAd,
} from '../../redux/actions'


const findScript = name => {
  const script = scripts[name]

  if (!script) {
    return { script: null }
  }

  const params = script.params.reduce(
    (obj, item) => ({
      ...obj,
      [item.name]: item.defaultValue || '',
    }),
    {},
  )

  return {
    script,
    params: script.params,
    state: params,
  }
}

@connect(
  store => ({ isLicenseValid: store.isLicenseValid }),
  {
    notifyWhenQueueFinished,
    showLoader,
    hideLoader,
    printLog,
    sendMetrikaEvent,
    maybeOpenAd,
  },
)
class CreateTaskCard extends Component {
  scriptName() {
    return this.props.name
  }

  constructor(props) {
    super(props)

    console.log(props)

    const { name, defaultParams } = props
    const { script, params, state } = findScript(name)

    this.state = {
      showAlertAfterFinish: false,
      shouldRedirectToLogs: false,
      delay: 5, // minutes
      defaultParams,
      params,
      script,
      ...(script ? state : {}),
      ...this.props.defaultParams || {},
    }
  }

  static getDerivedStateFromProps(props) {
    console.log('get derived state from props', props)

    const { name, defaultParams } = props

    const { script, params, state } = findScript(name)


    if (!script) return {
      defaultParams,
      params: [],
      script: null,
    }

    // this.setState({
    //   ...state,
    //   params,
    // })


    // this.setState({
    //   ...defaultParams,
    // })

    return {
      defaultParams: {
        ...state,
        ...defaultParams,
      },
      params,
      script,
    }

  }

  componentDidMount() {
    this.setUpAutoRun()
    this.fillDefaultParams()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.name !== prevProps.name) {
      this.fillDefaultParams()
    }
  }

  setUpAutoRun = () => {
    console.log('autoRun', this.props.AUTO_RUN)

    if (this.props.AUTO_RUN) {
      setTimeout(() => this.handleSubmit(), 5000)
    }
  }

  fillDefaultParams = () => {
    this.setState({
      ...this.state.defaultParams,
    })
  }

  extractScriptParams = () => {
    const {
      showAlertAfterFinish,
      shouldRedirectToLogs,
      delay,
      params,
      script,
      defaultParams,
      ..._params
    } = this.state

    return _params
  }

  handleCopyParams = () => {
    const _params = this.extractScriptParams()

    const str = JSON.stringify(_params)

    if (navigator.clipboard) {
      navigator.clipboard.writeText(str)
        .then(res => alert('Copied!'))
    } else {
      alert(str)
    }

  }

  handleSchedule = async () => {
    this.props.showLoader()

    const scriptName = this.scriptName()

    const wasStopped = instagram.isStopped

    wasStopped && instagram.start()

    const params = this.state.script.params
      .map(item => `${item.name} = ${this.state[item.name]}`)
      .join(`, `)

    this.props.printLog(`Scheduling script ${scriptName}: ${params}`)

    const startAt = Number(this.state.delay) * 60 * 1000 + Date.now()

    // this.props.printLog(`Start at: ${new Date(startAt)}`)

    if (!wasStopped) {
      alert(`
        Service indicates there is something else running.
        Be careful: Keep track manually if two different scripts don't overlap.
        If you're not sure, stop all other scripts before scheduling!
      `)
    }

    schedule.printLog = this.props.printLog
    schedule.add({ startAt, scriptName, params: this.state }, (err, res) => {
      this.props.printLog(`Scheduled task finished running!`)

      if (err) {
        this.props.printLog(`Error: ${err.message}`)
        alert(err.message)
      }

      if (res) {
        this.props.printLog(`Success: ${!!res}`)
      }

      console.log('error', err)
      console.log('result', res)

      wasStopped && instagram.kill()
      this.props.hideLoader()
    })

    this.props.printLog(
      `Task scheduled successfully. Please wait until it launches`,
    )

    this.handleRedirectToLogs()
  }

  handleSubmit = async () => {
    this.props.showLoader()

    this.props.maybeOpenAd()

    console.log('printLog', this.props.printLog)

    const scriptName = this.scriptName()

    const { showAlertAfterFinish } = this.state
    showAlertAfterFinish && this.props.notifyWhenQueueFinished()

    if (!instagram.isStopped) {
      alert(`Finish other tasks first!`)
      return
    }

    instagram.start()

    const params = this.state.script.params
      .map(item => `${item.name} = ${this.state[item.name]}`)
      .join(`, `)

    this.props.printLog(`Running script ${scriptName}: ${params}`)
    this.props.printLog(`<Link to="/script/${scriptName}?${this.state.script.params.map(item => `${item.name}=${encodeURIComponent(this.state[item.name])}`).join('&')}">Restart ${scriptName}</Link>`)

    this.props.printLog(`Started at ${new Date()}`)

    this.state.script
      .run(this.state, this.props.printLog)
      .then(res => this.props.sendMetrikaEvent(`task-success-${scriptName}`))
      .then(res => this.props.printLog(`Finished at ${new Date()}`))
      .then(res => this.props.printLog(message.PRO_HEADER))
      .then(res => this.props.printLog(message.PRO_TEXT.replace(/<br>/g, '')))
      .catch(err => {
        console.error(err)
        this.props.printLog(`Error: ${err.message}`)
        alert(err.message)
        this.props.sendMetrikaEvent(`task-error-${scriptName}`)
      })
      .finally(() => instagram.kill())
      .finally(() => this.props.hideLoader())

    this.props.sendMetrikaEvent(`task-started-${scriptName}`)

    this.handleRedirectToLogs()
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({ [name]: value })
  }

  handleCheckboxChange = event => {
    const name = event.target.name
    const value = event.target.checked

    this.setState({ [name]: value })
  }

  handleNumberChange = (name, value) => event => {
    this.setState({
      [name]: value,
    })
  }

  handleRedirectToLogs = () => {
    this.setState({
      shouldRedirectToLogs: true,
    })
  }

  render() {
    const { params, showAlertAfterFinish, shouldRedirectToLogs } = this.state

    const scriptName = this.scriptName()

    if (shouldRedirectToLogs) {
      return <Redirect push to="/logs" />
    }

    if (!this.state.script) {
      return (
        <CardFullWidthPage>
          <h2>Script with name '{scriptName}' is not found</h2>
        </CardFullWidthPage>
      )
    }

    return (
      <CardFullWidthPage>
        <div className="row no-gutters align-items-center">
          <div className="col mr-12">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-12">
              {this.state.script.name || scriptName}
              &nbsp;
            </div>
          </div>
        </div>

        {this.state.script.description && (
          <div className="row">
            <div className="col-8">
              <div
                className="pl-2 pb-2 pt-2"
                style={{ whiteSpace: 'pre-line' }}
              >
                {this.state.script.description}
              </div>
            </div>
          </div>
        )}

        {params.map(({ labelText, name, type, prefix, values, isHidden }, index) => (
          <div className="row" key={`${scriptName}-${name}`}>
            <div className="col-auto" style={isHidden ? { display: 'none' } : {}}>
              {(type !== 'checkbox') && (
                <label htmlFor={name}>{labelText || name}</label>
              )}

              {type === 'text' && (
                <div className="input-group mb-3">
                  {prefix && (
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id={`${scriptName}-prefix-symbol-${prefix}`}
                      >
                        {prefix}
                      </span>
                    </div>
                  )}

                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id={`${scriptName}-${name}`}
                    name={name}
                    aria-describedby={`${scriptName}-prefix-symbol-${prefix}`}
                    value={this.state[name]}
                    onChange={this.handleChange}
                  />
                </div>
              )}

              {type === 'textarea' && (
                <div className="input-group mb-3">
                  {prefix && (
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text"
                        id={`${scriptName}-prefix-symbol-${prefix}`}
                      >
                        {prefix}
                      </span>
                    </div>
                  )}

                  <textarea
                    className="form-control form-control-lg"
                    id={`${scriptName}-${name}`}
                    name={name}
                    aria-describedby={`${scriptName}-prefix-symbol-${prefix}`}
                    value={this.state[name]}
                    onChange={this.handleChange}
                  />
                </div>
              )}

              {type === 'checkbox' && (
                <div className="form-check d-inline-block">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`${scriptName}-${name}`}
                    name={name}
                    defaultChecked={this.state[name] ? 'checked' : null}
                    onChange={this.handleCheckboxChange}
                  />
                  <label className="form-check-label" htmlFor={name}>
                    {labelText || name}
                  </label>
                </div>
              )}

              {type === 'number' && (
                <div className="btn-group d-block mb-2">
                  {(values || [1, 2, 3, 5, 10]).map((num, index) => (
                    <Button
                      id={`${scriptName}-${name}`}
                      className={
                        num == this.state[name]
                          ? `btn-primary`
                          : `btn-secondary`
                      }
                      key={index}
                      data-value={num}
                      ymParams={{ num }}
                      ym={`${scriptName}-${name}-select`}
                      onClick={this.handleNumberChange(name, num)}
                    >
                      {num}
                    </Button>
                  ))}
                </div>
              )}

              {type === 'dropdown' && (
                <div className="input-group mb-3">
                  <select
                    className="form-control form-control-lg"
                    id={`${scriptName}-${name}`}
                    name={name}
                    value={this.state[name]}
                    onChange={this.handleChange}
                  >
                    {(values).map((num, index) => (
                      <option
                        key={index}
                        value={num}
                        data-value={num}
                      >
                        {num ? num.replace(/_/gi, ' ') : num}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        ))}

        <br />

        <div className="row">

          {this.props.AUTO_RUN && (
            <div className="pl-2 pb-2">
              <strong>Auto-Run is ON! Running script in 5 seconds...</strong>
              <br/>
              Close tab to stop
            </div>
          )}

        </div>

        <div className="row">
          <div className="col-auto">
            <div className="btn-group d-inline-block mr-2">
              <Button
                className={
                  this.state.script.isPRO ? 'btn-outline-primary' : 'btn-primary'
                }
                ym={`${scriptName}-submit`}
                onClick={this.handleSubmit}
              >
                Run!
              </Button>
            </div>

            {this.state.script.isPRO && (
              <div className="d-inline-block">
                {/*
                <div className="d-inline-block mr-2">
                  <a href="https://paypal.me/okhlopkov/10" target="_blank">
                    <ProBadge className="btn btn-success btn-icon-split">
                      <span class="icon">Unlock PRO</span>
                      <span class="text">5$/month</span>
                    </ProBadge>
                  </a>
                </div>
                */}

              </div>
            )}

            <div className="form-check d-inline-block">
              <input
                type="checkbox"
                className="form-check-input"
                id={`${scriptName}-showAlertAfterFinish`}
                name="showAlertAfterFinish"
                value={showAlertAfterFinish}
                onChange={this.handleChange}
              />
              <label
                className="form-check-label"
                htmlFor="showAlertAfterFinish"
              >
                Notify When Finished
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-auto">

            {this.props.BETA_TEST && (
              <div className="btn-group d-inline-block mr-2">
                <Button
                  className={'btn-outline-primary'}
                  ym={`${scriptName}-copy-params`}
                  onClick={this.handleCopyParams}
                >
                  Copy Script Params
                </Button>
              </div>
            )}

            {(scriptName !== 'schedule' && scriptName !== 'repeat') && (
              <div className="btn-group d-inline-block mr-2">
                <Link to={`/scripts/schedule/?scriptName=${scriptName}&scriptParams=${JSON.stringify(this.extractScriptParams())}`}>
                  <div className="btn btn-outline-primary">
                    <span>Schedule</span>
                  </div>
                </Link>
              </div>
            )}

            {false && scriptName !== 'repeat' && (
              <div className="btn-group d-inline-block mr-2">
                <Link to={`/scripts/repeat/?scriptName=${scriptName}&scriptParams=${JSON.stringify(this.extractScriptParams())}`}>
                  <div className="btn btn-outline-danger">
                    <span>Schedule Repeat</span>
                  </div>
                </Link>
              </div>
            )}


          </div>
        </div>

        {this.props.BETA_TEST && (
          <div className="row mt-2">
            <div className="col-auto">
              <div
                className="btn-group d-inline-block"
                data-tip="NOT STABLE YET! Use at your own risk"
                data-type="error"
                data-effect="solid"
              >
                <Button
                  className={'btn-outline-warning'}
                  ym={`${scriptName}-schedule`}
                  onClick={this.handleSchedule}
                >
                  <i className="fas fa-plus"></i>&nbsp; Schedule for{' '}
                  {this.state.delay} minutes
                </Button>
              </div>
            </div>

            <div className="col-auto">
              <input
                type="number"
                className="form-control"
                id={`${scriptName}-delay`}
                name="delay"
                value={this.state.delay}
                onChange={this.handleChange}
              />
            </div>
          </div>
        )}

        <ReactTooltip />
      </CardFullWidthPage>
    )
  }
}

export default CreateTaskCard
