import Lazy, { LazyError } from '../lazy'
import instagram from '../old/instagram_connector'
import { makeGenerator } from '../old/generator'

import {
  get_random,
  getCSV,
  download,
  download_array,
  instagramUrl,
  getURL,
  randomTimeout,
  sleep,
  skip,
} from './util'

const populateEmail = user => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g

  const [email, ...others] = Object.values(user)
    .filter(value => typeof value == 'string')
    .flatMap(value => value.match(emailRegexp) || [])
  console.log('found emails (using only first):', [email, ...others])

  return {
    ...user,
    email,
  }
}

export const load_followers = {
  name: 'Download full list of user followers',
  description: `
  Simply insert a username below and we will download a list in CSV format of the profiles that follow that user.
  Please allow some time for us to collect and download this data successfully.
  You can later use this data to perform very specific actions e.g. Follow Users From a List or for public data collection used in other campaigns.
  `,
  params: [
    {
      name: 'username',
      type: 'text',
      prefix: '@',
      labelText: 'Username',
      defaultValue: 'caffeinum',
    },
    {
      name: 'isFullInfo',
      type: 'checkbox',
      prefix: '',
      labelText:
        'Download full profile for each follower, including email, if there is one (takes much longer)',
      defaultValue: false,
    },
    {
      name: 'onlyEmail',
      type: 'checkbox',
      prefix: '',
      labelText: 'Download only emails',
      defaultValue: false,
    },
  ],
  run: async (
    { username, isFullInfo = false },
    printLog = console.log,
    timeout,
  ) => {
    const {
      user: { pk },
    } = await instagram.request(
      { method: 'get_user_info', params: [username] },
      true,
    )

    if (!pk || isNaN(pk)) throw new Error(`No user id: ${pk}`)

    // Phase 1: set up feed generator
    const follower_list = instagram.page_generator({
      method: 'get_user_followers',
      params: [pk],
    })

    // Phase 2: pages to list
    const users = new Lazy(follower_list)
      .peek((page, index) =>
        printLog(
          `Batch ${index} of followers for @${username} loaded: ${page.users.length}`,
        ),
      )
      .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))
      .map(page => makeGenerator(page.users))
      .flat()

    if (!isFullInfo) {
      const followers = await users.unwrap({ accumulate: true })

      printLog(`Followers for @${username} loaded: ${followers.length}`)
      printLog(`You can access them in window.followers or download using`)
      printLog(`\t\tdownloadCSV()`)
      printLog(`or`)
      printLog(`\t\tdownload('followers_${username}.csv', getCSV(followers))`)

      window.followers = followers
      window.downloadCSV = () => {
        download_array(followers, `followers_${username}`)
      }

      downloadCSV()

      try {
        localStorage.setItem(`followers_${username}`, JSON.stringify(followers))
      } catch (err) {
        printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
      }

      return
    } else {

      const followers_container = users
        .filter(skip(() => instagram.isStopped, printLog))
        .peek(user => printLog(`user: @${user.username}: `))
        .map(user =>
          instagram
            .request({ method: 'get_user_info', params: [user.pk] })
            .then(({ user }) => user),
        )
        .peek(user => printLog(`ok`, false))
        .map(user => populateEmail(user))
        .peek(user => printLog(`, email: ${user.email || 'none'}`, false))
        .peek(user => console.log('user', user))
        .sleep(sec => printLog(`Sleeping ${sec.toFixed(1)} sec`))

      const followers = await followers_container.unwrap()

      printLog(`Followers for @${username} loaded: ${followers.length}`)
      printLog(`You can access them in window.followers or download using`)
      printLog(`\t\tdownloadCSV()`)
      printLog(`or`)
      printLog(`\t\tdownload('followers_${username}.csv', getCSV(followers))`)
      window.followers = followers
      window.downloadCSV = () => {
        download_array(followers, `followers_${username}`)
      }

      downloadCSV()

      try {
        localStorage.setItem(`followers_${username}`, JSON.stringify(followers))
      } catch (err) {
        printLog(`You can ignore this error: Cant save to localStorage: file too big.`)
      }
    }
  },
}

export default load_followers
