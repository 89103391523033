import React from 'react'
import { Link } from 'react-router-dom'
// import icon from '../../../img/icon.png'
import banner from '../../../img/socialblaze.png'

export default () => (
  <ul
    className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    {/* Sidebar */}

    {/* <!-- Sidebar - Brand  --> */}
    <Link
      className="sidebar-brand d-flex align-items-center justify-content-center"
      to="/"
    >
      <img src={banner} style={{ width: '100%' }} alt="Social Blaze" />
    </Link>

    {/* <!-- Divider  --> */}
    <hr className="sidebar-divider my-0" />

    {/* <!-- Nav Item - Dashboard  --> */}
    <li className="nav-item active">
      <Link className="nav-link" to="/">
        <i className="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span>
      </Link>
    </li>

    {/* <!-- Divider  --> */}
    <hr className="sidebar-divider" />

    {/* <!-- Heading  --> */}
    <div className="sidebar-heading">Perform Actions</div>

    {/* <!-- Nav Item - Like Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseLike"
        aria-expanded="true"
        aria-controls="collapseLike"
      >
        <i className="fas fa-fw fa-heart"></i>
        <span>Perform Likes</span>
      </a>
      <div
        id="collapseLike"
        className="collapse"
        aria-labelledby="headingLike"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Components:</h6>  --> */}
          <Link className="collapse-item" to="/script/like_user">
            Like By User
          </Link>
          <Link className="collapse-item" to="/script/like_followers">
            Like Users Followers
          </Link>
          <Link className="collapse-item" to="/script/like_my_feed">
            Like Your Feed
          </Link>
          <Link className="collapse-item" to="/script/like_location">
            Like By Location
          </Link>
          <Link className="collapse-item" to="/script/like_by_hashtag">
            Like By Hashtag
          </Link>
          <Link className="collapse-item" to="/script/like_user_fans">
            Like By Post
          </Link>
          <Link className="collapse-item" to="/script/like_hashtag_fans">
            Like By Interest
          </Link>

          {/* <Link className="collapse-item" to="/like">All</Link> */}
          {/* <a className="collapse-item" href="like/hashtag.html">Hashtag</a> */}
          {/* <a className="collapse-item" href="like/user-medias.html">User Medias</a> */}
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Follow Collapse Menu  --> */}
    <li className="nav-item">
      <Link className="nav-link" to="/follow">
        <i className="fas fa-fw fa-user-friends"></i>
        <span>Follow Users</span>
      </Link>
    </li>

    {/* <!-- Nav Item - Unfollow Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseUnfollow"
        aria-expanded="true"
        aria-controls="collapseUnfollow"
      >
        <i className="fas fa-fw fa-user-times"></i>
        <span>Unfollow</span>
      </a>
      <div
        id="collapseUnfollow"
        className="collapse"
        aria-labelledby="headingUnollow"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          <Link className="collapse-item" to="/script/unfollow_everyone">
            Select Accounts
          </Link>
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Download Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseDownload"
        aria-expanded="true"
        aria-controls="collapseDownload"
      >
        <i className="fas fa-fw fa-file-download"></i>
        <span>Download</span>
      </a>
      <div
        id="collapseDownload"
        className="collapse"
        aria-labelledby="headingDownload"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Utilities:</h6>  --> */}
          <Link className="collapse-item" to="/script/load_followers">
            Followers Profiles
          </Link>
          <Link className="collapse-item" to="/script/load_stories">
            Stories
          </Link>
        </div>
      </div>
    </li>

    {/* <!-- Nav Item - Comment Collapse Menu  --> */}
    <li className="nav-item">
      <a
        className="nav-link collapsed"
        href="#"
        data-toggle="collapse"
        data-target="#collapseComment"
        aria-expanded="true"
        aria-controls="collapseDownload"
      >
        <i className="fas fa-fw fa-comment"></i>
        <span>Perform Comments</span>
      </a>
      <div
        id="collapseComment"
        className="collapse"
        aria-labelledby="headingDownload"
        data-parent="#accordionSidebar"
      >
        <div className="bg-white py-2 collapse-inner rounded">
          {/* <!-- <h6 className="collapse-header">Custom Utilities:</h6>  --> */}
          <Link className="collapse-item" to="/script/comment_by_hashtag">
            Comment by Hashtag
          </Link>
          <Link className="collapse-item" to="/script/comment_by_user">
            Comment by User
          </Link>
          <Link className="collapse-item" to="/script/comment_by_post">
            Comment by Posts
          </Link>
        </div>
      </div>
    </li>

    <hr className="sidebar-divider" />

    {/* <!-- Heading  --> */}
    <div className="sidebar-heading">Useful links</div>

    <li className="nav-item">
      <Link className="nav-link" to="/logs">
        <i className="fas fa-fw fa-file-alt"></i>
        <span>Logs</span>
      </Link>
    </li>

    <li className="nav-item">
      <Link className="nav-link" to="/settings">
        <i className="fas fa-cog fa-sm fa-fw"></i>
        <span>Settings</span>
      </Link>
    </li>

    <li className="nav-item">
      <Link className="nav-link" to="/faq">
        <i className="fas fa-question fa-sm fa-fw"></i>
        <span>FAQ</span>
      </Link>
    </li>

    <li className="nav-item">
      <a
        className="nav-link"
        href="https://kickspan.com/fullterms"
        target="_blank"
      >
        <i className="fas fa-fw fa-file-signature"></i>
        <span>Legal</span>
      </a>
    </li>

    {/* <!-- Sidebar Toggler (Sidebar)  --> */}
    {/* <div className="text-center d-none d-md-inline">
      <button className="rounded-circle border-0" id="sidebarToggle"></button>
    </div> */}

    {/* <!-- End of Sidebar  --> */}
  </ul>
)
