import React from 'react'
import { Card, CardHeader, CardBody } from '../components/markup/Card'

export default () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-xs-12 col-lg-12">
        <Card className="shadow mb-4">
          <CardHeader className="py-3">Kickspan & SocialBlaze FAQ</CardHeader>
          <CardBody>
            <p>
              <h2> How it works?</h2>
              Kickspan & SocialBlaze is an automation bot: it makes things
              you'll do to promote your Instagram, but automatically. It will
              like, follow, see stories of your potential customers. Configure
              the scripts to run so it targets your future users and see your
              followers grow!
            </p>
            <p>
              <h2> Can I buy followers?</h2>
              No, Kickspan & SocialBlaze doesn't sell followers, it brings you
              instead real people who will engage with your content and buy your
              products.
            </p>
            <p>
              <h2> My account is not completing actions what do I do?</h2>
              Here are our solutions
              <ul>
                <li>
                  You may need to use a VPN, we recommend https://betternet.co
                </li>
                <li>Please Re-login to your account</li>
                <li>Clear cookies and browser data</li>
                <li>
                  Check if there’s checkpoint_required error on your email or
                  Instagram app
                </li>
                <li>
                  Try a slower interaction speed at
                  https://dashboard.kickspan.com/settings
                </li>
              </ul>
            </p>
            <p>
              <h2> I can’t login to my Instagram account, what do I do?</h2>
              Here are our solutions
              <ul>
                <li>If you have two-step authentication enabled please disable it to login</li>
                <li>Reset your Instagram password here https://www.instagram.com/accounts/password/reset/?hl=en</li>
                <li>Check your email account linked to your Instagram account or your Instagram to see if there is a checkpoint verification</li>
              </ul>
            </p>
            {/* <p>
              <h2>
                Can I use Kickspan & SocialBlaze with more than one account?
              </h2>
              Yes! Please use Chrome profiles for that. More info here:
              https://github.com/instagrambot/GramUp/issues/38
            </p> */}
          </CardBody>
        </Card>
      </div>
    </div>
  </div>
)
