export const CONNECTION = Object.freeze({
  UNKNOWN: `Connection status: UNKNOWN`,
  NOT_INSTALLED: `Connection status: NOT_INSTALLED`,
  NOT_LOGGED_IN: `Connection status: NOT_LOGGED_IN`,
  LOGGED_IN: `Connection status: LOGGED_IN`,
  LICENSE_INVALID: `Connection status: LICENSE_INVALID`,
})

export const CONNECTION_MESSAGES = {
  [CONNECTION.UNKNOWN]: `Unknown error`,
  [CONNECTION.NOT_INSTALLED]: `Extension is not installed or not detected`,
  [CONNECTION.NOT_LOGGED_IN]: `Connected to the extension, but it's not logged in. Please login via pressing extension logo`,
  [CONNECTION.LOGGED_IN]: `Website connected to the extension.`,
  [CONNECTION.LICENSE_INVALID]: `License invalid, disconnected`,
}

export const message = {
  PRO_HEADER: ``,
  PRO_TEXT: `
  Thanks for using SocialBlaze
  `,
}

export const IS_FAMILY_ENABLED = false
export const AD_URL = 'http://deloplen.com/afu.php?zoneid=2940698'
export const BACKEND_URL = `https://cluster.likeup.me/integrations/subscriptions/list`
export const CHARGEBEE_PLAN_IDS = [
  'kickspan',
  'kickspan-40-per-month',
  'kickspan-influencer',
  'test_kickspan',
  // socialblaze: https://socialblaze.chargebee.com/plans
  '40permonth',
  '39.99-pack',
  'instagram-growth',
  'socialblazetrial',
  'Socialblazeactive',
  'social-blaze-3995',
]
