import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from '../../components/dumb/Button'
import { CardFullWidthPage } from '../../components/markup'
import { declensionOfUsers } from '../../scripts/util'
import {
  hideLoader,
  notifyWhenQueueFinished, printLog, sendMetrikaEvent,
  showLoader,
} from '../../redux/actions'
import { followUsernameList } from '../../old/likeItems'
import { likePhotosByUsername } from '../../services'

class FollowByListCard extends Component {
  state = {
    usersToFollow: [],
    newUserToFollow: '',
    showAlertAfterFinish: false,
    shouldRedirectToLogs: false,
    usersToFollowCount: 50,
    usersToFollowSelectionIndexStart: 1,
  }

  handleFollowUsersFromList = async () => {
    this.props.showLoader()
    this.props.sendMetrikaEvent(`task-start-follow-from-list`)

    const { usersToFollow, showAlertAfterFinish } = this.state;

    showAlertAfterFinish && this.props.notifyWhenQueueFinished()

    try {
      if (!instagram.isStopped) {
        alert(`Please stop all other tasks before running!`)
        return
      }

      // TODO Add select N.
      followUsernameList(
        usersToFollow,
        this.state.usersToFollowCount,
        this.state.usersToFollowSelectionIndexStart - 1,
        this.props.printLog
      );

      this.handleRedirectToLogs();
    } catch (err) {
      console.error(err)
      this.props.printLog(`Error: ${err.message}`)
      alert(err.message)
    } finally {
      this.props.hideLoader()
    }
  }

  handleRedirectToLogs = () => {
    this.props.handleRedirectToLogs(true);
  }

  handlePhotosNumberChange = (value) => {
    this.setState({usersToFollowCount: value});
  }

  handleRemoveUserFromList = (name) => {
    this.setState((prevState) => {
      return {
        usersToFollow: prevState.usersToFollow.filter(user => user !== name)
      };
    })
  }

  handleSubmitToList = (list) => {
    this.setState({ usersToFollow: [...list] });
  }

  handleFileInputChange = event => {
    const file = event.target.files[0];

    const filenameArr = file.name.split('.')
    if (filenameArr[filenameArr.length - 1] !== 'txt' && filenameArr[filenameArr.length - 1] !== 'csv') {
      alert('Unsupported file. Currently working with .txt and .csv only');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const contents = event.target.result;
      const filteredContent = contents
        .split(' ').join(',')
        .split('\n').join(',')
        .split(', ').join(',')
        .split(',');

      const clearedValues = filteredContent.filter(item => item.trim());

      this.setState((prevState) => {
        return {
          newUserToFollow: `${prevState.newUserToFollow} ${clearedValues} `,
          usersToFollow: [...prevState.usersToFollow, ...clearedValues]
        }
      });
    };

    reader.onerror = function(event) {
      alert("Файл не может быть прочитан! код " + event.target.error.code);
    };

    reader.readAsText(file);
  }

  handleTextAreaChange = event => {
    const value = event.target.value.trim();

    const separatedValues = value
      .split(' ').join(',')
      .split('\n').join(',')
      .split(', ').join(',')
      .split(',');

    const clearedValues = separatedValues.filter(item => item.trim());

    this.setState({newUserToFollow: event.target.value})
    this.handleSubmitToList(new Set(clearedValues))
  }

  handleStartingIndexChange = event => {
    console.log(event.target.value)
    this.setState({
      usersToFollowSelectionIndexStart: Number(event.target.value),
    })
    // this.state.usersToFollowSelectionIndexStart
  }

  render() {
    const { usersToFollow, showAlertAfterFinish } = this.state;

    return (
      <CardFullWidthPage>
        <div className="row no-gutters align-items-center">
          <div className="col mr-12">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-12">
              Follow users from list
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-auto">
            <label htmlFor="newUserToFollow">Add Username To The List (you can use newline <br/>characters, commas, dots and spaces as separators)</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                  <span className="input-group-text" id="hash-symbol">
                    @
                  </span>
              </div>
              <textarea className="form-control" placeholder="8hennessy" value={this.state.newUserToFollow} onChange={this.handleTextAreaChange} />
            </div>
            <div className="mb-2">Total: {this.state.usersToFollow.length}</div>

            <div className="custom-file mb-3">
              <input type="file" className="custom-file-input"
                     id="validatedCustomFile" onChange={this.handleFileInputChange} />
              <label className="custom-file-label"
                     htmlFor="validatedCustomFile">Import List</label>
            </div>

            <div className="row">
              <div className="col-auto mt-2">
                <div>
                  <label htmlFor="follow-select">Follow {this.state.usersToFollowCount} users from list</label>
                </div>
                <div className="btn-group mt-1" id="follow-select">
                  {[25, 50, 100, 200, 300].map((num, index) => (
                    <Button
                      className="btn-secondary"
                      key={index}
                      data-value={num}
                      ymParams={{ num }}
                      ym={`follow-select`}
                      onClick={() => this.handlePhotosNumberChange(num)}
                    >
                      {num}
                    </Button>
                  ))}
                </div>
              </div>

              <div className="col-auto mt-2" style={{ display: 'none' }}>
                <label htmlFor="usersToFollowSelectionIndexStart">Starting from user #{this.state.usersToFollowSelectionIndexStart} in list</label>

                <div className="form-check mt-1 pl-0">
                  <input
                    type="number"
                    className="form-control"
                    id="usersToFollowSelectionIndexStart"
                    name="usersToFollowSelectionIndexStart"
                    value={this.state.usersToFollowSelectionIndexStart}
                    onChange={this.handleStartingIndexChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-auto">
            <div className="form-check mt-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="showAlertAfterFinish1"
                name="showAlertAfterFinish1"
                value={showAlertAfterFinish}
                onChange={this.handleChange}
              />
              <label
                className="form-check-label"
                htmlFor="showAlertAfterFinish1"
              >
                Notify When Finished
              </label>
            </div>
          </div>
        </div>

        <br />

        <div className="row">
          <div className="col-auto">
            <div>
              <Button
                className={usersToFollow.length > 0 ? "btn-success" : "btn-warning"}
                ym={`follow-submit`}
                onClick={this.handleFollowUsersFromList}
              >
                Follow {this.state.usersToFollowCount > 0 && this.state.usersToFollowCount} {declensionOfUsers(this.state.usersToFollowCount)}
              </Button>
            </div>
            
          </div>
        </div>
      </CardFullWidthPage>
    )
  }
}

export default connect(
  null,
  {
    likePhotosByUsername,
    notifyWhenQueueFinished,
    showLoader,
    hideLoader,
    printLog,
    sendMetrikaEvent,
  },
)(FollowByListCard)
