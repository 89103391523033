import React from 'react'
import ReactDOM from 'react-dom'
import { withRouter, BrowserRouter } from 'react-router-dom'
import { connect, Provider } from 'react-redux'
import isMobile from 'is-mobile'
import Honeybadger from 'honeybadger-js'
import OnMobileModal from './components/markup/OnMobileModal'
import { CONNECTION } from './constants'

import { SubscriptionPage } from './pages'
import Router from './router'

import instagram from './old/instagram_connector'

import { shouldCheckLicense, fetchSubscriptionData, checkHasAccess } from './license'

import store from './redux/store'
import {
  updateConnectionStatus,
  updateInstagramServiceStatus,
  updateSubscriptions,
  showLoader,
  hideLoader,
  setUser,
  sendMetrikaEvent,
  showErrorMessage,
  printLog,
  updateStats,
  updateConfig,
} from './redux/actions'

import { connectExtension, fetchStats, sendConfig } from './services'

import { Wrapper, ScrollToTop, LogoutModal } from './components/markup'

class __App extends React.Component {

  async checkLicense(user) {

    const subs = await fetchSubscriptionData(user.username)

    this.props.updateSubscriptions(subs)

    if (shouldCheckLicense()) {
      const [ activeSub ] = checkHasAccess(subs)

      if (activeSub && activeSub.isValid) {

      } else {
        this.props.updateConnectionStatus(CONNECTION.LICENSE_INVALID)

        window.alert('License not valid, please check your payment with ChargeBee provider')
      }
    }
  }

  componentDidMount() {
    this.props.showLoader()

    connectExtension()
      .then(({ user, status, error }) => {

        this.props.updateConnectionStatus(status)

        this.props.printLog(`App loaded ${new Date()}`)
        this.props.printLog(status)

        this.props.sendMetrikaEvent(`app-loaded`, { status, timestamp: Date.now() })

        if (status === CONNECTION.LOGGED_IN) {
          this.props.sendMetrikaEvent(`app-loaded-logged-in`, { timestamp: Date.now() })
        }

        if (user) {
          this.props.setUser(user)
        } else if (error) {
          this.props.showErrorMessage(error)
        }

        this.checkLicense(user)

        fetchStats()
          .then(stats => this.props.updateStats(stats))
          .catch(err => console.error(err))

        sendConfig()
          .then(config => this.props.updateConfig(config))
          .catch(err => console.error('Cant update config, update extension', err))

        // TODO: HACK
        setInterval(() => {
          this.props.updateInstagramServiceStatus(instagram)
        }, 500)
      })
      .finally(() => this.props.hideLoader())
  }

  render() {
    if (this.props.connection.status === CONNECTION.LICENSE_INVALID) {
      return (
        <Wrapper>
          <SubscriptionPage />
        </Wrapper>
      )
      // return (
      //   <SubscriptionPage />
      // )
    }

    return (
      <React.Fragment>
        {isMobile() && <OnMobileModal />}
        <Wrapper>
          <Router />
        </Wrapper>
        <ScrollToTop />
        <LogoutModal />
      </React.Fragment>
    )
  }
}

export const App = withRouter(
  connect(
    store => ({
      connection: store.connection,
      isLicenseValid: store.isLicenseValid,
    }),
    {
      updateConnectionStatus,
      updateInstagramServiceStatus,
      updateSubscriptions,
      showLoader,
      hideLoader,
      setUser,
      sendMetrikaEvent,
      showErrorMessage,
      printLog,
      updateStats,
      updateConfig,
    },
  )(__App),
)

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

Honeybadger.configure({
  apiKey: '682efcf4',
  environment: 'production'
});

export default App
