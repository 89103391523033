import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { CardFullWidthPage } from '../components/markup'
import { Button } from '../components/dumb/Button'

import {
  notifyWhenQueueFinished,
  showLoader,
  hideLoader,
  printLog,
  sendMetrikaEvent,
} from '../redux/actions'

import { likePhotosByHashtag } from '../services'

class __LikeHashtagPage extends React.Component {
  state = {
    hashtag: 'cats',
    nPhotos: 10,
    showAlertAfterFinish: false,
    shouldRedirectToLogs: false,
  }

  handleLikeHashtagButton = async () => {
    this.props.showLoader()

    const { hashtag, nPhotos, showAlertAfterFinish } = this.state

    showAlertAfterFinish && this.props.notifyWhenQueueFinished()

    try {
      if (!instagram.isStopped) {
        alert(`Please stop all other tasks before running!`)
        return
      }

      likePhotosByHashtag(hashtag, nPhotos, this.props.printLog)
        .then(() => this.props.sendMetrikaEvent(`task-success-like-hashtag`))
        .catch(err => {
          console.error(err)
          this.props.printLog(`Error: ${err.message}`)
          alert(err.message)
          this.props.sendMetrikaEvent(`task-error-like-hashtag`)
        })
        .finally(() => this.props.hideLoader())

      this.props.sendMetrikaEvent(`task-started-like-hashtag`)

      this.handleRedirectToLogs()
    } catch (err) {
      console.error(err)
      this.props.printLog(`Error: ${err.message}`, false)
      alert(err.message)
    } finally {
      this.props.hideLoader()
    }
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.setState({ [name]: value })
  }

  handlePhotosNumberChange = (num = 10) => event => {
    this.setState({
      nPhotos: num,
    })
  }

  handleRedirectToLogs = () => {
    this.setState({
      shouldRedirectToLogs: true,
    })
  }

  render() {
    const {
      nPhotos,
      hashtag,
      showAlertAfterFinish,
      shouldRedirectToLogs,
    } = this.state

    if (shouldRedirectToLogs) {
      return <Redirect push to="/logs" />
    }

    return (
      <div className="container-fluid">
        <CardFullWidthPage>
          <div className="row no-gutters align-items-center">
            <div className="col mr-12">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-12">
                Like medias by hashtag
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <label htmlFor="hashtag">Hashtag</label>

              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="hash-symbol">
                    #
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="hashtag"
                  name="hashtag"
                  aria-describedby="hash-symbol"
                  value={hashtag}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="showAlertAfterFinish"
                  name="showAlertAfterFinish"
                  value={showAlertAfterFinish}
                  onChange={this.handleChange}
                />

                <label
                  className="form-check-label"
                  htmlFor="showAlertAfterFinish"
                >
                  Notify When Finished
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <div className="btn-group">
                {[10, 20, 50].map((num, index) => (
                  <Button
                    className="btn-secondary"
                    key={index}
                    data-value={num}
                    ymParams={{ num }}
                    ym={`like-hashtag-select`}
                    onClick={this.handlePhotosNumberChange(num)}
                  >
                    {num}
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <br />

          <div className="row">
            <div className="col-auto">
              <div>
                <Button
                  className="btn-success"
                  ym="like-hashtag-submit"
                  onClick={this.handleLikeHashtagButton}
                >
                  Like {nPhotos} photos
                </Button>
              </div>
            </div>
          </div>
        </CardFullWidthPage>
      </div>
    )
  }
}

export default connect(
  null,
  {
    likePhotosByHashtag,
    notifyWhenQueueFinished,
    showLoader,
    hideLoader,
    printLog,
    sendMetrikaEvent,
  },
)(__LikeHashtagPage)
